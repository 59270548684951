/*
 *
 *   GULL - React Bootstrap Admin Template
 *
 *
*/

@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "../iconsmind/iconsmind.css";
@import "variables";

// @import 'themes/lite-blue';
@import "themes/lite-blue.scss";
@import "~bootstrap/scss/bootstrap.scss";
@import "bootstrap-icons/font/bootstrap-icons.css";

@import "globals/globals";
//@import "custom/index.scss";

/*** base app ***/
.table {
    .row-disabled {
        text-decoration: line-through;
        opacity: 0.7;
    }
    .row-subtitle {
        color: rgb(107, 119, 140);
        font-size: smaller;
    }
}

.ErrorCatcher {
    display: flex;
    justify-content: center;
}