.tenants-details-id {
    padding-left: 12px;
}

.tenants-details-tab-container {
    padding: 20px 10px;
    position: relative;

    .tenants-details-tab-container__label {
        position: absolute;
        top: -12px;
        background-color: $white;
    }

    .activaction-status-container,
    .encryption-status-container {
        border: 1px solid $gray-300;
        padding: 20px 10px;
        position: relative;

        button.btn-secondary:disabled {
            background-color: $blue  !important;
            color: $white  !important;
            border-color: $blue;
            opacity: 1;
        }

        .validUpto-container {
            display: flex;
            align-items: center;
            justify-content: space-between;

            input[type=date] {
                margin-left: 10px;
                height: 26px;
                border: 1px solid $gray-200;
            }

            .validUpto-container__upgrade-validUpTo {
                button:disabled {
                    display: none;
                }

                div {
                    color: $green;
                }
            }
        }

        .activation-button {
            button.btn-secondary {
                &:first-child {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }

                &:last-child {
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                }
            }
        }

        .activaction-status-container__label,
        .encryption-status-container__label {
            position: absolute;
            top: -12px;
            background-color: $white;
        }

    }

    .copy-clipboard-button,
    .refresh-button {
        margin-left: 5px;
        border: 0;

        &.refreshing {
            cursor: default;
            box-shadow: none;

            &:hover {
                box-shadow: none;
            }
        }

        .module-loader {
            position: static;
            background: none;
            height: auto;

            .spinner {
                position: static;
                width: 13px;
                height: 13px;
            }
        }
    }

    .copy-to-clipboard {
        border: 0;
        cursor: auto;
    }

    .tabs-button {
        button.btn {
            border: 1px solid $gray-300;
            border-bottom: 1px solid $white;
            border-radius: 0;
            position: relative;
            border-top-right-radius: 10px;
            background-color: $gray-300;
            margin-bottom: -4px;

            &:hover {
                border: 1px solid $gray-300;
                border-bottom: 1px solid $white;
            }

            &.active {
                background-color: transparent;
                margin-bottom: 0;

                &:after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    bottom: -2px;
                    border-bottom: 2px solid $white;
                    left: 0;
                }
            }
        }
    }

    .tenants-details-tab-request,
    .tenants-details-tab-response {
        border: 1px solid $gray-300;
        padding: 20px 10px;
        overflow-x: auto;

        .tenants-details-tab-json {
            background-color: $gray-200;
            border: 1px solid $gray-300;
            overflow-x: auto;
            padding: 10px 20px;
        }

        label {
            position: relative;
            padding-left: 30px;
        }
    }
}