.api-filter-container {
    position: relative;
    border: 1px solid $gray-300;
    padding: 60px 20px 20px;

    .filter-label {
        position: absolute;
        top: -12px;
        background-color: $white;
        z-index: 4;
    }

    input,
    select {
        height: 26px;
        width: 100%;
        border: 1px solid $gray-200;

        &:disabled {
            background-color: $gray-200;
        }
    }

    .format-date-error {
        color: $red;
    }

    .accordion-button {
        position: absolute;
        left: 0;
        top: 0;
        text-align: left;
        height: 45px;
        border-bottom: 1px solid $gray-300;

        &:hover {
            border-bottom: 1px solid $gray-300;
            background-color: $gray-200;
        }

        i {
            position: absolute;
            right: 20px;
            font-size: 25px;
            transform: rotate(-180deg);
            transition: transform 0.5s;
        }

        &.closed i {
            transform: rotate(0);
            transition: transform 0.5s;
        }

        .container-filters-values,
        .accordion-label {
            display: flex;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            justify-content: center;
            padding-right: 54px;
            font-size: 8px;

            @media (min-width: 576px) {
                font-size: 13.008px;
            }

            div {
                margin: 0 2px;
            }
        }
    }

    .button-container {
        width: 100%;
        text-align: right;

        .btn {
            height: 26px;
            border: 1px solid $gray-300;
            padding: 0 20px;
        }
    }

    .accordion-content {
        overflow: hidden;
        max-height: 1000px;
        transition: max-height 0.5s ease-in-out;
    }

    &.closed {
        padding: 22px 20px;
        transition: padding 1s ease-in-out;

        .accordion-content {
            max-height: 0;
        }
    }
}