.logTraceFilter-container {

    input,
    select {
        height: 26px;
        width: 100%;
        border: 1px solid $gray-300;
    }

    .button-container {
        width: 100%;
        text-align: right;

        .btn {
            height: 26px;
            border: 1px solid $gray-300;
            padding: 0 20px;
        }
    }
}

.no-result {
    color: $red;
    text-align: right;
}