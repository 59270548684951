.table-modal-button {
    cursor: pointer;
}

.logs-modal {

    .modal-header {
        .modal-title {
            font-size: 30px;
            width: 100%;
            text-align: center;
        }

        .btn-close {
            font-size: 20px;
        }
    }

    .modal-body {
        position: relative;
        min-height: 300px;

        .logs-modal__error {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 30px;
            color: $red;
        }
    }
}