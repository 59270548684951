.logs-container {
    border: 1px solid $gray-300;
    padding: 20px;
    position: relative;

    .levels-filter-container .form-category-container {
        align-items: center;

        @media (max-width: 575px) {
            justify-content: center;
        }

        select {
            height: 26px;
            width: 200px;
            border: 1px solid #dee2e6;
        }
    }

    @media (max-width: 575px) {
        .levels-filter-container {
            flex-direction: column;

            span {
                display: none;
            }

            .dropdown {
                margin-top: 20px;
            }
        }

        .react-bootstrap-table-pagination {
            align-items: center;
            flex-direction: column-reverse;

            [class*="col-"] {
                text-align: center;
                margin-top: 10px;
            }

            .react-bootstrap-table-pagination-list {
                justify-content: center;
                display: flex;
            }
        }
    }

    .react-bootstrap-table {
        overflow-x: auto;

        @media (max-width: 575px) {
            table {
                margin-bottom: 0;
            }
        }
    }

    .logs-container__label {
        background-color: $white;
        position: absolute;
        top: -12px;
    }

    select:disabled {
        background-color: $gray-200;
    }

    .react-bootstrap-table-page-btns-ul {
        overflow-x: auto;
        position: relative;

        button:disabled {
            opacity: .4;
            background-color: transparent;
            color: $blue;
            border-color: $gray-300;
        }

        .prev-button,
        .next-button {
            position: sticky;
            z-index: 4;
        }

        .prev-button {
            left: 0;
        }

        .next-button {
            right: 0;
        }
    }

    .process-time {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .badge-grey {
            background-color: #f8f9fa;
            color: $black;
        }

        .badge-yellow {
            background-color: #ffc107;
        }

        .badge-red {
            background-color: #dc3545;
        }
    }
}