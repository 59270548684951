.logs-tab-container {
    border: 1px solid $gray-300;
    padding: 20px 10px;
    position: relative;

    .logs-tab-container__label {
        position: absolute;
        top: -12px;
        background-color: $white;
    }

    button.btn {
        border: 1px solid $gray-300;
        border-bottom: 1px solid $white;
        border-radius: 0;
        position: relative;
        border-top-right-radius: 10px;
        background-color: $gray-300;
        margin-bottom: -4px;

        &:hover {
            border: 1px solid $gray-300;
            border-bottom: 1px solid $white;
        }

        &.active {
            background-color: transparent;
            margin-bottom: 0;

            &:after {
                content: '';
                position: absolute;
                width: 100%;
                bottom: -2px;
                border-bottom: 2px solid $white;
                left: 0;
            }
        }
    }

    .logs-tab-request,
    .logs-tab-response {
        border: 1px solid $gray-300;
        padding: 20px 10px;
        overflow-x: auto;

        .logs-tab-json {
            background-color: $gray-200;
            border: 1px solid $gray-300;
            overflow-x: auto;
            padding: 10px 20px;
        }

        .custom-checkbox {
            position: relative;

            input[type="checkbox"] {
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                width: 20px;
                height: 20px;
                border: 1px solid $gray-300;
                cursor: none;
                appearance: none;

                &::before {
                    position: absolute;
                    content: '';
                    display: none;
                    width: 5px;
                    height: 10px;
                    border-style: solid;
                    border-color: $black;
                    border-width: 0 1px 1px 0;
                    top: 45%;
                    left: 50%;
                    transform: translate(-50%, -50%) rotate(45deg);
                }

                &:checked::before {
                    display: block;
                }
            }
        }

        label {
            position: relative;
            padding-left: 30px;
        }
    }
}