.dashboard-container {

    .show-tenant-details {
        border: 0 !important;
    }

    .dashboard-errors {
        font-size: 24px;
        color: $red;
    }

    .btn {
        height: 26px;
        border: 1px solid $gray-300;
        padding: 0 20px;
        float: right;
    }

    input,
    select {
        height: 26px;
        width: 100%;
        border: 1px solid $gray-200;

        &:disabled {
            background-color: $gray-200;
        }
    }

    .dashboard-card {
        height: 100%;

        .card-body {
            align-items: center;
        }
    }

    .dashboardPie-container {
        position: relative;

        .dashboardPie-container__values {
            ul {
                list-style: decimal;
            }
        }

        .recharts-layer {
            cursor: pointer;
        }
    }

    .format-date-error {
        color: $red;
    }
}

.date-confirmation-modal .btn-close {
    display: none;
}

.full-data-modal {
    tr {
        cursor: pointer;
    }

    .fulldata-modal-header {
        flex-direction: column;
        margin-right: 20px;
    }

    .modal-header {
        .modal-title {
            font-size: 30px;
            width: 100%;
            text-align: center;
        }

        .btn-close {
            font-size: 20px;
        }
    }

    .modal-body {
        position: relative;
        min-height: 300px;

        .react-bootstrap-table {
            overflow-x: auto;
        }

        .logs-modal__error {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 30px;
            color: $red;
        }

        .process-time {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .badge-grey {
                background-color: #f8f9fa;
                color: $black;
            }

            .badge-yellow {
                background-color: #ffc107;
            }

            .badge-red {
                background-color: #dc3545;
            }
        }

        .module-loader {
            position: absolute;
            height: 100%;

            .spinner {
                position: absolute;
                top: 35%;
                left: 50%;
            }
        }
    }
}